<template>
  <div class="row justify-content-center bg-auth">
    <div
        class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center"
    >
      <!-- Heading -->
      <div class="text-center">
        <img
            src="../../assets/img/logo/qevo_blue.svg"
            class="img-fluid"
            alt="..."
            width="240"
        />
      </div>

      <br>
      <h1 class="display-4 text-center mb-3">Crie uma conta</h1>
      <!-- Subheading -->
      <p class="text-muted text-center mb-5">Preencha os dados abaixo para criar uma conta no
        Qevo para sua empresa.</p>

      <form @submit.prevent="sendRequest">

        <div class="form-group">
          <!-- Label -->
          <label class="form-label"> Nome da Empresa </label>

          <!-- Input -->
          <input
              v-model="name"
              type="text"
              class="form-control"
              placeholder="Nome da sua empresa"
              :class="Array.isArray(errors.name) ? 'is-invalid' : ''"
          />
          <div v-if="Array.isArray(errors.name) " class="invalid-feedback">
            <span v-for="error in errors.name" :key="error">{{ error }}</span>
          </div>
        </div>

        <!-- Email address -->
        <div class="form-group">
          <!-- Label -->
          <label class="form-label"> Endereço de E-mail </label>

          <!-- Input -->
          <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="nome@dominio.com"
              :class="Array.isArray(errors.email) ? 'is-invalid' : ''"
          />
          <div v-if="Array.isArray(errors.email) " class="invalid-feedback">
            <span v-for="error in errors.email" :key="error">{{ error }}</span>
          </div>
        </div>

        <!-- Phone number -->
        <div class="form-group">
          <!-- Label -->
          <label class="form-label"> Telefone </label>

          <!-- Input -->
          <input
              v-model="phoneNumber"
              type="text"
              class="form-control"
              placeholder="Número de telefone"
              :class="Array.isArray(errors.phone) ? 'is-invalid' : ''"
          />
          <div v-if="Array.isArray(errors.phone) " class="invalid-feedback">
            <span v-for="error in errors.phone" :key="error">{{ error }}</span>
          </div>
        </div>

        <!-- Phone number -->
        <div class="form-group">
          <!-- Label -->
          <label class="form-label"> Quantidade de Colaboradores </label>

          <!-- Input -->
          <input
              v-model="numberOfEmployees"
              type="number"
              class="form-control"
              placeholder="Colaboradores"
              min="0"
          />

        </div>

        <div class="form-group">
          <vue-recaptcha siteKey="6LcOiXMUAAAAAOD5D54fHqVJ7KtXU5k2B2E-LC7I"
                         size="normal"
                         theme="light"
                         :tabindex="0"
                         @verify="recaptchaVerified"
                         @expire="recaptchaReset"
                         @fail="recaptchaFailed"
                         ref="vueRecaptcha"
                         :class="Array.isArray(errors.g_recaptcha_response) ? 'is-invalid' : ''">
          </vue-recaptcha>
          <div v-if="Array.isArray(errors.g_recaptcha_response)" class="invalid-feedback">
            <span v-for="error in errors.g_recaptcha_response" :key="error">{{ error }}</span>
          </div>

        </div>

        <!-- Submit -->
        <button type="submit" class="btn btn-lg w-100 btn-primary mb-3" v-if="!postSent || hasErrors()">Criar minha conta</button>
        <button type="submit" class="btn btn-lg w-100 btn-light mb-3" disabled v-if="postSent && !hasErrors()">Criando...</button>
      </form>

      <!-- Link -->
      <p class="text-center">
        <small class="text-muted text-center">
          Ja possui sua conta?
          <a href="/login">Faça o login</a>.
        </small>
      </p>
    </div>
    <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
      <!-- Image -->
      <div
          class="bg-cover h-100 min-vh-100 mt-n1 me-n3"
          :style="{
          'background-image':
            'url(' +
            require('../../assets/img/covers/unauthenticated_screen_3.png') +
            ')',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import {mapGetters} from "vuex";

export default {
  components: {
    vueRecaptcha
  },

  data() {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      numberOfEmployees: 0,
      recaptcha: '',
      postSent: false
    }
  },

  mounted() {
    this.$store.dispatch('auth/resetSignupErrors');
  },

  computed: {
    ...mapGetters('auth', {
      errors: 'getSignupErrors',
      user: 'user'
    }),
  },

  watch: {},

  methods: {
    sendRequest() {
      this.recaptchaReset();
      this.postSent = true

      const payload = {
        email: this.email,
        phone: this.phoneNumber,
        name: this.name,
        max_agents_login: this.numberOfEmployees,
        g_recaptcha_response: this.recaptcha
      };

      this.$store.dispatch('auth/signup', payload);
    },

    recaptchaVerified(response) {
      this.recaptcha = response;
    },

    recaptchaReset() {
      this.$refs.vueRecaptcha.reset();
    },

    recaptchaFailed() {
    },
    hasErrors() {
      return Object.keys(this.errors).length > 0
    }
  }
};
</script>
